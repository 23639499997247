/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"

import Header from "./header"
import Footer from "./Footer/footer"
import "./layout.css"

const Layout = ({ children }) => {
  return (
    <div className="mainWrapper">
      
      <Header />
      <section>
        {children}
      </section>
      <Footer />
    
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
