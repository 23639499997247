import React, { useState } from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import downarrow from '../images/landing/down.svg';

import { Container, Grid } from "@material-ui/core"
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';

import './header.scss'

import UnbiasedLogo from '../images/unbiasedLogo.png';
import UnbiasedLogoBlack from '../images/UnbiasedLogo2.png';
import Menu from '../images/landing/menu.svg';
import close from '../images/landing/close.svg';

import {
  MAIN_HOME,
  MAIN_DATAANNOTATION,
  MAIN_USECASES,
  MAIN_DATACOLLECTION,
  MAIN_PRICING,
  MAIN_BLOG
} from '../config/routes';
import {
  CONTACT_US
} from '../config/constants'

import ContactModal from './ContactModal';

const Header = () => {
  const url = typeof window !== 'undefined' ? window.location.pathname : '';
  const temp = url.split('/');
  const [data, setData] = useState(false);
  const [open, setOpen] = useState(false);


  const viewMenu = () => {
    setData(true)
  }
  const closeMenu = () => {
    setData(false)
  }

  const requesterRoute = [
    MAIN_BLOG,
    MAIN_PRICING,
    MAIN_USECASES,
    MAIN_DATAANNOTATION,
    MAIN_DATACOLLECTION,
  ]

  if (
    process.env.GATSBY_APP_USER_TYPE === 'worker' &&
    (requesterRoute.includes(url) || temp[1] === 'blogs')
  ) {
    window.location = MAIN_HOME;
  }

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <header>
      <Container>
        <ContactModal open={open} handleClose={handleClose} source={CONTACT_US} />
        <Grid direction="row" container alignItems="center">
          <Grid item >
            <Grid container alignItems="center">
              <Link to={MAIN_HOME}>
                {
                  url === MAIN_BLOG || temp[1] === 'blogs' ? <img src={UnbiasedLogoBlack} height="20" alt="unbiasedlogo" /> :
                    <img src={UnbiasedLogo} height="20" alt="unbiasedlogo" />
                }
              </Link>
            </Grid>
          </Grid>
          {
            process.env.GATSBY_APP_USER_TYPE === "requester" && <><Grid item xs>
              {data ? (<ul className="nav navBarMobile">
                <li className="closeMenu"><IconButton color="primary" onClick={() => closeMenu()} className="menuIcon" aria-label="upload picture" component="span">
                  <img src={close} alt="Menu" width="18" />
                </IconButton></li>
                <li><Link to="/">HOME</Link></li>
                <li><Link to={MAIN_DATAANNOTATION}>DATA ANNOTATION</Link></li>
                <li ><Link to={MAIN_DATACOLLECTION} >DATA COLLECTION</Link></li>

                <li><Link to={MAIN_USECASES}>USE CASES</Link></li>
                <li><Link to={MAIN_PRICING}>PRICING</Link></li>
                <li><Link to={MAIN_BLOG}>BLOG</Link></li>
              </ul>) : null}
              <ul className="nav navBar">
                <li>
                  <div>
                    <Link to="/" className={(url === MAIN_BLOG || temp[1] === 'blogs') && "activeBlog"}>
                      SERVICES<img className="imageLeft" src={downarrow} width="10" alt="Next" className={url === MAIN_BLOG || temp[1] === 'blogs' ? "iconBlack" : "iconwhite"} />
                    </Link>
                    <ul className="menuTab">
                      <li ><Link to={MAIN_DATAANNOTATION} >DATA ANNOTATION</Link></li>
                      <li ><Link to={MAIN_DATACOLLECTION} >DATA COLLECTION</Link></li>
                    </ul>
                  </div>
                </li>
                <li><Link className={url === MAIN_USECASES ? "activeTab" : url === MAIN_BLOG || temp[1] === 'blogs' ? "activeBlog" : ""} to={MAIN_USECASES}>USE CASES</Link></li>
                <li><Link className={url === MAIN_PRICING ? "activeTab" : url === MAIN_BLOG || temp[1] === 'blogs' ? "activeBlog" : ""} to={MAIN_PRICING}>PRICING</Link></li>
                <li><Link className={url === MAIN_BLOG || temp[1] === 'blogs' ? "activeBlogScetion" : url === MAIN_BLOG || temp[1] === 'blogs' ? "activeBlogScetion" : ""} to={MAIN_BLOG}>BLOG</Link></li>
              </ul>
            </Grid>
              <Grid item>
                <Button onClick={handleOpen} target="_blank">CONTACT US</Button>
                <IconButton color="primary" onClick={() => viewMenu()} className="menuIcon" aria-label="upload picture" component="span">
                  <img src={Menu} alt="Menu" width="18" className={url === MAIN_BLOG || temp[1] === 'blogs' ? "iconBlack" : "iconwhite"} />
                </IconButton>
              </Grid>
            </>
          }
          {process.env.GATSBY_APP_USER_TYPE === "worker" && <div className="btWrap">
            <Grid item container justifyContent="flex-end" className="btContainer">
              <Button className="btSignIn" href={process.env.GATSBY_APP_LOGIN_URL}>Sign in</Button>
              <Button className="btRegister" href={process.env.GATSBY_APP_LOGIN_URL}>Register</Button>
            </Grid>
          </div>
          }
        </Grid>
      </Container>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
