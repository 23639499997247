import React, { useState } from "react"
import './footer.scss'

import { Grid } from "@material-ui/core"
import { Link } from "gatsby"
import Button from '@material-ui/core/Button';

import twitter from '../../images/landing/soc-twitter.svg'
import facebook from '../../images/landing/soc-facebook.svg'
import linkedin from '../../images/landing/soc-linkedin.svg'
import telegram from '../../images/landing/soc-telegram.svg'
import youtube from '../../images/landing/soc-youtube.svg'
import vimeo from '../../images/landing/soc-vimeo.svg'
import UnbiasedLogo from '../../images/unbiasedLogo.png';
import Map from '../../images/icons/map.svg';
import Mail from '../../images/icons/email.svg';
import {
  MAIN_DATAANNOTATION,
  MAIN_USECASES,
  MAIN_DATACOLLECTION,
  MAIN_PRIVACY,
  MAIN_FAQ,
  MAIN_PRICING,
  MAIN_BLOG
} from '../../config/routes';
import {
  TWITTER_PAGE,
  TELEGRAM_PAGE,
  FACEBOOK_PAGE,
  YOUTUBE_CHANNEL,
  LINKEDIN_PAGE,
  VIMEO_CHANNEL,
  CONTACT_US,
  UNBIASED_CC
} from '../../config/constants';
import ContactModal from '../ContactModal';


const Footer = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (

    <footer className="main-footer">
      <div className="footer-wrapper">
        <ContactModal open={open} handleClose={handleClose} source={CONTACT_US} />
        {
          process.env.GATSBY_APP_USER_TYPE === "requester" && <Grid container >
            <Grid item xs={12} sm={6} md>
              <h5>SERVICES</h5>
              <ul className="nav">
                <li>
                  <Link to={MAIN_DATAANNOTATION}>Data Annotation</Link>
                </li>
                <li>
                  <Link to={MAIN_DATACOLLECTION}>Data Collection</Link>
                </li>
                <li>
                  <Link to={process.env.GATSBY_APP_MARKETPLACE} target="_blank">Marketplace</Link>
                </li>
              </ul>
            </Grid>
            <Grid item xs={12} sm={6} md >
              <h5>EXPLORE</h5>
              <ul className="nav">
                <li>
                  <Link to={MAIN_USECASES}>Use Cases</Link>
                </li>
                <li>
                  <Link href={UNBIASED_CC}>Innovations</Link>
                </li>
              </ul>
            </Grid>
            <Grid item xs={12} sm={6} md >
              <h5>COMPANY</h5>
              <ul className="nav">
                <li>
                  <Link>About</Link>
                </li>
                <li>
                  <Link to={MAIN_PRICING}>Pricing</Link>
                </li>
                <li>
                  <Link>Press</Link>
                </li>
                <li>
                  <Link>Careers</Link>
                </li>
              </ul>
            </Grid>
            <Grid item xs={12} sm={6} md >
              <h5>RESOUCES</h5>
              <ul className="nav">
                <li>
                  <Link>Case Studies</Link>
                </li>
                <li>
                  <Link to={MAIN_BLOG}>Blogs</Link>
                </li>
                <li>
                  <Link to={MAIN_FAQ} target="_blank">FAQs</Link>
                </li>
              </ul>
            </Grid>

            <Grid item xs={12} sm={12} md >
              <ul className="nav">
                <li>
                  <img src={UnbiasedLogo} className="footerLogo" alt="logo" height="24" />
                </li>
                <li>
                  <Link><span><img src={Map} width="18" alt="Map" /></span> Goteborg, Sweden</Link>
                </li>
                <li>
                  <Link><span><img src={Mail} width="16" alt="Mail" /></span> contact@unbiased.ml</Link>
                </li>
              </ul>
              <div className="soc-med-links">
                <ul>
                  <li><a href={TWITTER_PAGE} target="_blank" rel="noopener noreferrer"><img src={twitter} alt="Twitter" width="16" /> </a></li>
                  <li><a href={FACEBOOK_PAGE} target="_blank" rel="noopener noreferrer"><img src={facebook} alt="facebook" width="16" /> </a></li>
                  <li><a href={LINKEDIN_PAGE} target="_blank" rel="noopener noreferrer"><img src={linkedin} alt="linkedin" width="16" /> </a></li>
                  <li><a href={TELEGRAM_PAGE} target="_blank" rel="noopener noreferrer"><img src={telegram} alt="telegram" width="16" /> </a></li>
                  <li><a href={YOUTUBE_CHANNEL} target="_blank" rel="noopener noreferrer"><img src={youtube} alt="youtube" width="16" /> </a></li>
                  <li><a href={VIMEO_CHANNEL} target="_blank" rel="noopener noreferrer"><img src={vimeo} alt="vimeo" width="16" /> </a></li>
                </ul>
                {
                  process.env.GATSBY_APP_USER_TYPE === "requester" && <Button onClick={handleOpen} target="_blank">CONTACT US</Button>
                }
              </div>
            </Grid>
          </Grid>
        }

        <Grid container direction="column" className="soc-med-links" alignItems="center" justify="center">
          {
            process.env.GATSBY_APP_USER_TYPE === "worker" &&
            <div className="soc-med-links">
              <ul>
                <li><a href={TWITTER_PAGE} target="_blank" rel="noopener noreferrer"><img src={twitter} alt="Twitter" width="16" /> </a></li>
                <li><a href={FACEBOOK_PAGE} target="_blank" rel="noopener noreferrer"><img src={facebook} alt="facebook" width="16" /> </a></li>
                <li><a href={LINKEDIN_PAGE} target="_blank" rel="noopener noreferrer"><img src={linkedin} alt="linkedin" width="16" /> </a></li>
                <li><a href={TELEGRAM_PAGE} target="_blank" rel="noopener noreferrer"><img src={telegram} alt="telegram" width="16" /> </a></li>
                <li><a href={YOUTUBE_CHANNEL} target="_blank" rel="noopener noreferrer"><img src={youtube} alt="youtube" width="16" /> </a></li>
                <li><a href={VIMEO_CHANNEL} target="_blank" rel="noopener noreferrer"><img src={vimeo} alt="vimeo" width="16" /> </a></li>
              </ul>
            </div>
          }
          <p className="privacyPolicy">
            © ALL RIGHTS RESERVED UNBIASED.ML
          <span>
              <Link to={MAIN_PRIVACY}> PRIVACY POLICY </Link>
            </span> |
          <span><Link to={MAIN_PRIVACY}>GDPR POLICY</Link></span>
          </p>
        </Grid>
      </div>
    </footer >
  )
}

export default Footer
